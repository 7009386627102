import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 20 20",
  fill: "#212529",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 16 4 Z M 5.332 10 L 8.6641 13.333 L 14.6641 7.333 L 13.7266 6.3867 L 8.6641 11.4463 L 6.2734 9.0596 L 5.332 10 Z",
      fill: "inherit"
    }, null, -1)
  ])))
}
export default { render: render }